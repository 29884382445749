<script setup>
import { defineProps } from 'vue';
import { formatNullablePersonalString } from '@/utils/text-utils';


const props = defineProps({
    user: Object
});
</script>


<template>
    <div class="user-info d-flex flex-column border-0 bg-white border-radius-4 p-4 mt-3">
        <p class="user-info__title normal-font font-14 text-black no-margin-vertical">
            Подробная информация о пользователе
        </p>
        <div class="user-info__phone margin-top-14">
            <span class="user-info__phone-title text-gray font-14 thin-font">
                Телефон пользователя: 
            </span>
            <span class="user-info__phone-text text-black font-14 normal-font">
                {{ props.user.phone }}
            </span>
        </div>
        <div class="user-info__email margin-top-14">
            <span class="user-info__email-title text-gray font-14 thin-font">
                E-mail пользователя: 
            </span>
            <span class="user-info__email-text text-black font-14 normal-font">
                {{ formatNullablePersonalString(props.user.ticketEmail) }}
            </span>
        </div>
    </div>
</template>