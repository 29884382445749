<script setup>
import { defineProps, ref } from 'vue';

const props = defineProps({
    addAttachment: Function
});
const picker = ref(null);
</script>

<template>
    <div class="attach-file d-flex flex-row bg-gray h-100 py-1 px-2 
        align-items-center border-radius-4">
        <label
            for="attachments-picker" 
            role="button"
            class="attach-file__file-title text-black my-0">
            Прикрепить файл
            <img 
                class="attach-file__file-icon"
                src="@/assets/img/icons/icon-attachment.svg"
                alt="Иконка вложения" 
                draggable="false"/>
        </label>
        <input 
            class="attach-file__file-input"
            type="file" 
            id="attachments-picker" 
            ref="picker"  
            @input="props.addAttachment(picker)"
            accept="image/*,video/*,.pdf,.doc,.docx"
            multiple hidden>
    </div>
</template>