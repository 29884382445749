<script setup>
import AppealAttachment from './AppealAttachment.vue';
import CheckboxButton from '@/components/layout/CheckboxButton.vue';
import AttachFileButton from '@/components/layout/AttachFileButton.vue';
import FileView from '@/components/layout/FileView';
import { sendMessage, loadFile } from '@/api/appeals';
import { defineProps, computed, reactive, ref } from 'vue';


const messageArea = ref(null);
const viewDialog = ref(null);

const flags = reactive({
    messagesLoading: true,
    firstLoad: true,
    messageSending: false
});

const props = defineProps({
    showError: Function,
    appeal: Object,
    callback: Function
});

const MAX_FILES = 9;
const MAX_FILE_SIZE_BYTE = 50 * 1024 * 1024;
const newAttachments = reactive({
  items: []
});
const picker = ref(null)

function addAttachment(_picker) {
  if (newAttachments.items.length > MAX_FILES) {
    return;
  }

  picker.value = _picker;

  let file = picker.value.files[0];
  if (file.size > MAX_FILE_SIZE_BYTE) {
    props.showError('Прикрепляемый файл должен иметь размер менее 50 Мб');
    return;
  }

  const reader = new FileReader(); 
  // when file is load, file src is set
  reader.addEventListener('load', (event) => {
    file.src = event.target.result;
  });
  reader.readAsDataURL(file);

  // adding url to file object
  file.url = URL.createObjectURL(file);
  newAttachments.items.push(file);
}

function deleteAttachment(attachment) {
  newAttachments.items = newAttachments.items.filter(att => att != attachment);

  if (newAttachments.items.length == 0)
    picker.value.value = "";
}

function viewAttachment(attachment) {
  if (attachment.type.startsWith('image')) {
    let dialog = viewDialog.value;
    dialog.show(attachment);
  } else {
    window.open(attachment.url, '_blank');
  }
}

const newMessage = reactive({
    appealId: '',
    comment: '',
    isSupplementRequired: false,
    guids: ''
});

async function _sendMessage() {
  if (newMessage.comment.length < 1) {
    props.showError('Сообщение не может быть пустым');
    return;
  } 

  flags.messageSending = true;

  let files = await _loadFiles();
  if (files.length != newAttachments.items.length) {
    props.showError('При загрузке файлов произошла ошибка. Попробуйте позже.');
    return;
  }

  newMessage.appealId = props.appeal.appealId;
  let guids = files.map(f => f.fileGuid);
  newMessage.guids = guids.join(';');
  
  let result = await sendMessage(newMessage);
  if (result.error) {
    props.showError('При отправке сообщения произошла ошибка. Попробуйте позже.');
  } else {
    dropMessageFields();
    dropAreaHeight();

    props.callback(result);
  }

  flags.messageSending = false;
}

function dropMessageFields() {
  newMessage.comment = '';
  newMessage.guids = '';
  newAttachments.items = [];
}

async function _loadFiles() {
  let uploadedFiles = [];
  for (let i = 0; i < newAttachments.items.length; i++) {
    let file = newAttachments.items[i];

    let result = await loadFile(file);
    if (result.error)
      break;

    uploadedFiles = uploadedFiles.concat(result.file);
  }

  return uploadedFiles;
}

function messageAreaHeight() {
  if (newMessage.comment) {
    let textArea = messageArea.value;
    let rowCount = Math.floor(textArea.scrollHeight/22);

    if (rowCount <= 5)
      textArea.style.height = textArea.scrollHeight + 'px';
  } else 
    dropAreaHeight();
}

function dropAreaHeight() {
  let textArea = messageArea.value;
  textArea.style.height = '';
}

const isValidCommentLength = computed(() => newMessage.comment.length < 1000);

const messageSendButtonText = computed(() => (flags.messageSending) ? 'Отправка... ' : 'Отправить');

function switchSupplementRequirementState(state) {
  newMessage.isSupplementRequired = state;
}
</script>

<template>
  <div 
    class="reply-area mt-auto d-flex flex-column flex-grow-0
    border-3 gray-border border-radius-4">
    <file-view ref="viewDialog"/>
    <div 
        ref="commentArea"
        class="reply-area__input row d-flex flex-row user-select-none justify-content-center">
        <textarea 
            v-model="newMessage.comment"
            class="reply-area__textarea form-control no-border"
            ref="messageArea"
            @input="messageAreaHeight()"
            wrap="hard"
            maxlength="1000"
            draggable="false"
            rows="2"
            placeholder="Введите текст..." 
            @focusout="dropAreaHeight()"
            @focusin="messageAreaHeight()"
            :class="{ 'mb-2': isValidCommentLength }" required/>
        <p v-if="!isValidCommentLength" 
            class="reply-area__length-alert font-12 text-red card-margin"
            style="margin-top: 4px;">
            Размер сообщения не должен превышать 1000 символов
        </p>
        <div class="reply-area__options row d-flex flex-row padding-4 
            thin-font font-14 w-100 justify-content-between">
            <checkbox-button
              class="col-auto"
              text="Требуется ответ от пользователя"
              :field="newMessage.isSupplementRequired"
              :switch="switchSupplementRequirementState">
            </checkbox-button>
            <attach-file-button
              class="col-auto"
              :add-attachment="addAttachment">
            </attach-file-button>
            <button 
                class="reply-area__send-button btn btn-primary p-2 col" 
                id="save" 
                type="button" 
                @click="_sendMessage">
                <span v-if="flags.messageSending" 
                    class="reply-area__button-loader spinner-grow spinner-grow-sm" 
                    role="status" 
                    aria-hidden="true"/>
                {{ messageSendButtonText }}
            </button> 
        </div>
        <p 
            class="reply-area__file-alert font-12 text-gray ps-2 pt-1" 
            style="margin-bottom: 0; padding-top: 0; padding-bottom: 0">
            Поддерживаются следующие форматы документов: pdf, doc, docx
        </p>
    </div>
    <div class="reply-area__attachments">
      <ul 
          class="reply-area__attachments-list d-inline-block w-100 ps-0 mb-0">
          <li 
              class="reply-area__attachment-item list-without-style d-flex"
              v-for="attachment in newAttachments.items" 
              :key="attachment">
              <appeal-attachment
                  :attachment="attachment" 
                  :isLocal="true"
                  :on-click="viewAttachment" 
                  :on-delete="deleteAttachment"/>
          </li>
          <li v-if="newAttachments.items.length >= 10"
              class="reply-area__new-attachment padding-8 d-flex align-items-center collapse">
              <p class="reply-area__new-attachment-text text-gray font-16 mid-font">
                  Нельзя прикрепить более 10 файлов
              </p>
          </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/css/pages/appeal.scss';


.reply-area__attachments-list {
  height: 100%; 
  overflow-y: scroll;
  overflow-x: hidden;
  flex: 1;
}

.reply-area__new-attachment {
  width: 25%;
}
</style>