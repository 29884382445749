<script setup>
import { defineProps, ref } from 'vue';


const props = defineProps({
    switch: Function,
    text: String,
    field: Boolean
});

const state = ref(props.field);

function switchState() {
    state.value = !state.value;
    props.switch(state.value);
}
</script>

<template>
    <div 
        class="checkbox-button d-flex flex-row bg-gray h-100 py-1 px-2 
            align-items-center border-radius-4"
        role="button"
        @click="switchState()">
        <input 
            class="checkbox-button__supplement-check form-check-input 
            no-margin-vertical" 
            type="checkbox" 
            v-model="state">
        <p class="checkbox-button__supplement-text text-black 
            my-0 ps-2">
            {{ text }}
        </p>
    </div>
</template>

<style scoped>

.checkbox-button__supplement-check {
  width: 21px;
  height: 21px;
  background-color: white;
  border: none;
}

.checkbox-button__supplement-check:checked {
  background-size: 90% 80%;
}
</style>