 <script setup>
import { defineProps } from 'vue';


const props = defineProps({
    deviceInfo: Object
});
</script>

<template>
    <div class="device-info d-flex flex-column bg-white border-radius-4 p-4 mt-3">
        <p class="device-info__title normal-font font-14 text-black no-margin-vertical">
            Информация об устройстве пользователя
        </p>
        <div class="device-info__os margin-top-14">
            <span class="device-info__os-title text-gray font-14 thin-font">
                Операционная система: 
            </span>
            <span class="device-info__os-text text-black font-14 normal-font">
                {{ props.deviceInfo.osVersion }}
            </span>
        </div>
        <div class="device-info__model margin-top-14">
            <span class="device-info__model-title text-gray font-14 thin-font">
                Модель устройства: 
            </span>
            <span class="device-info__model-text text-black font-14 normal-font">
                {{ props.deviceInfo.deviceModel }}
            </span>
        </div>
        <div class="device-info__version margin-top-14">
            <span class="device-info__version-title text-gray font-14 thin-font">
                Версия приложения: 
            </span>
            <span class="device-info__version-text text-black font-14 normal-font">
                {{ props.deviceInfo.appVersion }}
            </span>
        </div>
    </div>
</template>