function getUser() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user) {
        user.accessToken = localStorage.getItem('user.token');
        return user;
    } else {
        return null;
    }
}

function getOrgName() {
    return localStorage.getItem('orgName');
}

export {
    getUser,
    getOrgName
}