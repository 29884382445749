<script setup>
import { defineProps, computed } from 'vue';


const props = defineProps({
    appeal: Object
});

const getThemeName = computed(() => (props.appeal.themes) ? props.appeal.themes.themes[0].name : '-');

const getOperatorName = computed(() => (props.appeal.operator) ? props.appeal.operator?.fio : '-');
</script>

<template>
    <div class="main-info d-flex flex-column bg-blue p-4 border-0 border-radius-4">
        <h5 class="main-info__title card-title text-white font-18 normal-font">
            Обращение {{ props.appeal.number }}
        </h5>
        <div class="main-info__date mt-3">
            <span class="main-info__date-title text-white font-14 thin-font">
                Дата и время создания: 
            </span>
            <span class="main-info__date-text font-14 text-white font-14 normal-font">
                {{ props.appeal.createDate }}
            </span>
        </div>
        <div class="main-info__theme margin-top-14">
            <span class="main-info__theme-title text-white font-14 thin-font">
                Тема обращения: 
            </span>
            <span class="main-info__theme-text font-14 text-white font-14 normal-font">
                {{ getThemeName }}
            </span>
        </div>
        <div v-if="appeal.operator" 
            class="main-info__operator margin-top-14">
            <span class="main-info__operator-title text-white font-14 thin-font">
                Оператор: 
            </span>
            <span class="main-info__operator-text font-14 text-white font-14 normal-font">
                {{ getOperatorName }}
            </span>
        </div>
        <div class="main-info__status margin-top-14">
            <span class="main-info__status-title text-white font-14 thin-font">
                Текущий статус: 
            </span>
            <span class="main-info__status-text font-14 text-white font-14 normal-font">
                {{ props.appeal.status.text }}
            </span>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import '@/assets/css/colors.scss';


.bg-blue {
  background: $primary-color;
}
</style>