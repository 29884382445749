<script setup>
import { FILE_URL, getFileName } from '@/utils/appeal-utils';
import { defineProps, ref } from 'vue';


const props = defineProps({
  attachment: Object,
  isLocal: Boolean,
  onDelete: Function,
  onClick: Function,
  onDownloadError: Function
});

const getSize = (size) => {
  const kilobyte = 1024;
  const megabyte = 1024 * 1024;

  if (size < kilobyte)
    return size + ' байт';

  if (size < megabyte)
    return Math.round(size/kilobyte) + ' Кб';

  return Math.round(size/megabyte) + ' Мб';
};

async function click() {
  if (props.isLocal)
    props.onClick(props.attachment);
  else 
    downloadClick();
}

const downloadStarted = ref(false);

async function downloadClick() {
  if (downloadStarted.value)
    return;

  downloadStarted.value = true;
  await props.onClick(props.attachment, props.onDownloadError);
  downloadStarted.value = false;
}

const getUrl = () => {
  if (props.isLocal)
    return props.attachment?.url ?? '';

  if (props.attachment?.mime?.startsWith('image'))
    return process.env.VUE_APP_API_URL 
        + FILE_URL 
        + props.attachment?.fileGuid ?? ''; 
        
  return '';
};
</script>

<template>
  <div class="attachment border-radius-4
      h-100 w-100 d-flex flex-row user-select-none opacity-50">
    <div v-if="downloadStarted" 
      class="attachment__loader-layout">
      <span class="spinner-border text-light"></span>
    </div>
    <div class="attachment__attachment-info d-flex flex-row over-im 
      w-100 padding-8"
      :style="{ 'background-image': 'url(' + getUrl() + ')' }" @click="click()" 
      role="button">
      <div class="attachment__file-type d-flex flex-column">
        <img v-if="attachment.fileType === 0"
          class="attachment__type-image icon-24" 
          src="@/assets/img/icons/icon-image.svg"
          alt="Иконка"/>
        <img v-else 
          class="attachment__type-file icon-24"
          src="@/assets/img/icons/icon-file.svg" 
          alt="Иконка"/>
      </div>
      <div class="attachment__info d-flex flex-column left-margin h-100 w-100">
        <p class="attachment__file-name text-white font-14 mid-font text-break 
          no-margin-vertical max-lines">
          {{ getFileName(props.attachment) }}
        </p>
        <p v-if="isLocal" 
          class="attachment__file-size text-white font-14 mid-font text-break no-margin-vertical">
          {{ getSize(attachment.size) }}
        </p>
        <div v-if="!isLocal" 
          class="attachment__file-actions d-flex flex-row-reverse mt-auto align-items-center">
          <img 
            class="icon-24" 
            src="@/assets/img/icons/icon-download.svg" 
            alt="download-icon"/>
          <p class="text-white font-12 no-margin-vertical">
            Скачать
          </p>
        </div>
      </div>
    </div>
  </div>
  <button v-if="isLocal" 
    class="btn-close attachment-delete opacity-100" 
    @click="onDelete(attachment)"/>
</template>

<style lang="scss" scoped>
@import '@/assets/css/colors.scss';
.attachment {
  margin-right: 0;
  background-color: #0B0B0B;
  z-index: 1;
  position: relative;
  flex: 1;
}

.attachment__delete {
  width: 8px;
  height: 8px;
  background-color: $secondary-color;
  border-radius: 8px;
  margin-left: 0;
  margin-top: 6px;
}

.attachment__over-image {
  z-index: 1;
  border-radius: 4px;
}

.attachment__attachment-info {
  height: 100px;
  background-size: 100%, 100%;
  box-shadow: inset 0 0 150px #000000;
}

.max-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.attachment__loader-layout {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  background: rgba(0,0,0,0.5);
}
</style>