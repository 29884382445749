import statuses from "@/assets/statuses.json";

const closedStatuses = [statuses[6], statuses[8]];
const openStatuses = [statuses[0], statuses[1], statuses[2], statuses[3], statuses[4], statuses[5], statuses[7]];

const FILE_URL = "/api/files/file?fileGuid=";

function getAvailableStatuses(currentStatus, lastChange = null) {
    switch(currentStatus) {
        case 5:
            return [statuses[2], statuses[3], statuses[1]];
        case 1:
            return [statuses[2], statuses[3]];
        case 2:
            return [statuses[1], statuses[3]];
        case 3:
            return [statuses[1], statuses[2]];
        case 7: {
            let diff = Math.abs(new Date() - new Date(lastChange));
            let diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));
            
            if (lastChange !== null && diffDays >= 3) {
                return [statuses[8]];
            } else {
                return [];
            }
        }
        default:
            return [];
    }
}

function mapAppeal(appeal) {
    let _appeal = Object.assign({}, appeal);
    let dt = new Date(appeal.createDate);
    _appeal.createDate = dt.toLocaleString();
    _appeal.status = statuses[_appeal.status];
    return _appeal;
}

function getAllStatuses() {
    return statuses;
}

function getClosedStatuses() {
    return closedStatuses;
}

function getOpenStatuses() {
    return openStatuses;
}

function getFileName(file) {
    if (file.name)
        return file.name;

    let filePathParts = file.path.split(file.fileGuid + '_');
    if (filePathParts.length > 1)
        return filePathParts[1];

    return file.fileGuid;
}

async function download(attachment, callback) {
    try {
        let url = process.env.VUE_APP_API_URL + FILE_URL + attachment.fileGuid;
      
        const response = await fetch(url);
        
        if (response.status !== 200) {
          throw new Error('Произошла ошибка при cкачивании файла.');
        }

        const blob = await response.blob();

        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);

        if (attachment.mime.startsWith('image') || attachment.mime.includes('pdf'))
          downloadLink.target = '_blank';
        else
          downloadLink.download = getFileName(attachment) || "download"; 

        document.body.appendChild(downloadLink);
        downloadLink.click();

        setTimeout(() => {
          URL.revokeObjectURL(downloadLink.href);
          document.body.removeChild(downloadLink);
        }, 100);
    } catch (error) {
        callback(error.message);
    }
}

export {
    getAvailableStatuses,
    mapAppeal,
    getAllStatuses,
    getClosedStatuses,
    getOpenStatuses,
    getFileName,
    FILE_URL,
    download
}