<script setup>
import AppealAttachment from './AppealAttachment';
import { download } from '@/utils/appeal-utils.js';
import { defineProps } from 'vue';


const props = defineProps({
  message: Object,
  onDownloadError: Function
});
</script>

<template>
  <div class="operator-message d-flex flex-column align-items-end 
    px-0">
    <div class="operator-message__info d-flex flex-row
      justify-content-end align-items-center py-2">
      <p class="operator-message__title text-gray font-14 thin-font 
        my-0 me-2">
        Ответ от оператора
      </p>
      <img 
        class="operator-message__icon icon-24" 
        alt="profile-icon" 
        src="@/assets/img/icons/profile-logo.svg">
    </div>
    <div class="operator-message__message d-flex flex-column gray-border
      border-3 border-radius-4 align-items-end padding-12 col-10">
      <p class="operator-message__date thin-font font-14 text-gray">
        {{ new Date(props.message.createDate).toLocaleString() }}
      </p>
      <p 
        class="operator-message__comment thin-font text-black comment-text">
        {{ props.message.comment }}
      </p>
      <ul 
        class="operator-message__attachments d-flex flex-row justify-content-between w-100" 
        id="attachments-list">
        <li class="operator-message__attachment list-without-style d-flex"
            v-for="attachment in props.message.files" 
            :key="attachment">
            <appeal-attachment 
              :attachment="attachment" 
              :is-local="false" 
              :on-click="download"
              :on-download-error="onDownloadError"/>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
.icon-24 {
  width: 24px;
  height: 24px;
}

.operator-message__attachment {
  float: right !important;
  width: calc(33% - 12px);
}

.operator-message__comment {
  text-align: right;
}
</style>