<script setup>
import BottomComponent from '@/components/popup/BottomComponent';
import IconError from '@/assets/img/icons/icon-error.svg';
import IconCheck from '@/assets/img/icons/icon-check-mark.svg';
import { ref, defineExpose, computed, reactive } from 'vue'


const window = ref(null);

const alertInfo = reactive({
  text: '',
  show: false
});

function show (txt, isError) {
  alertInfo.show = isError;
  alertInfo.text = txt;
  window.value.show();
}

const srcPath = computed(() => {
  return (alertInfo.show) 
    ? IconError
    : IconCheck
});

function hide() {
  window.value.hide();
}

defineExpose({
  show,
  hide
});
</script>

<template>
  <bottom-component 
    class="alert"
    ref="window">
    <div class="alert__icon d-flex align-content-end">
      <img
        class="alert__icon-image"
        :src="srcPath" alt="icon">
    </div>
    <div class="alert__content d-flex justify-content-center">
      <p class="alert__text mid-font text-black font-16 no-margin-vertical text-center">
        {{ alertInfo.text }}
      </p>
    </div>
  </bottom-component>
</template>

<style scoped>
.alert__icon-image {
  width: 28px;
  height: 24px;
  margin-right: 14px;
}
</style>