<script setup>
import FileView from '@/components/layout/FileView';
import BottomDialog from '@/components/popup/BottomDialog.vue';
import Alert from '@/components/popup/Alert.vue';
import AppealChat from '@/components/appeals/chat/AppealChat';
import AppealInfoPanel from '@/components/appeals/info/AppealInfoPanel';
import { ref, onMounted, reactive, defineProps, computed } from 'vue';
import { useRouter } from 'vue-router';
import { getAvailableStatuses } from '@/utils/appeal-utils';
import { getUser } from '@/utils/auth-header';
import {
  getOperators,
  setStatus,
  setOperator,
  getMessages,
  getOrgAppeal
} from '@/api/appeals';


const props = defineProps({
    id: String,
    text: String
});

const router = useRouter();

// render flags
const flags = reactive({
    messagesLoading: true,
    firstLoad: true,
    messageSending: false
});

// view refs
const viewDialog = ref(null);
const bottomDialog = ref(null);
const alert = ref(null);

// reactive fields
const user = ref(null);
const statuses = reactive({
  items: []
});
const appeal = ref(null);

async function _getAppeal() {
  let result = await getOrgAppeal(props.id, user.value.uid);

  if (!result.error) {
    appeal.value = result;
    statuses.items = getAvailableStatuses(
        appeal.value.status.id, appeal.value.modifyDate
    );
    await _getOperators();
  
    if (flags.firstLoad)
      flags.firstLoad = false;
  }

  if (!(appeal.value?.appealId) || result.statusCode == 404) {
    await router.push("/page-not-found");
  }
}

const operators = reactive({
  items: []
});

async function _getOperators() {
  let _operators = await getOperators(appeal.value.org.organizationId);
  if (!_operators.error) {
    operators.items = _operators.data;
  }
}

const messages = reactive({
  items: []
});

async function _getMessages() {
  flags.messagesLoading = true;
  messages.items = appeal.value?.messages ?? [];
  
  let _messages = await getMessages(appeal.value.appealId);
  if (!_messages.error) {
    messages.items = _messages.data;
  }

  flags.messagesLoading = false;
}

function showAlert(text) {
  alert.value.show(
    text,
    true
  );
}

function showBottomDialog(text, callback, id) {
  bottomDialog.value.show(
    text,
    () => {
      callback(id);
    }
  );
}

async function _changeOperator(operatorId) {
  flags.messagesLoading = true;
  let statusChange = await setOperator(appeal.value.appealId, operatorId);
  if (!statusChange.error) {
    await _getAppeal();
  }

  flags.messagesLoading = false;
}

async function _changeStatus(statusId) {
  flags.messagesLoading = true;

  let statusChange = await setStatus(appeal.value.appealId, statusId);
  if (!statusChange.error) {
    if (statusChange.status === 8)
      await router.push({ path: '/appeals', replace: true, query: { tab: 'appeals' } });
    else {
      await _getAppeal();
      await _getMessages();
    }
  }

  flags.messagesLoading = false;
}

function messageCallback(result) {
    appeal.value = result;
    statuses.items = getAvailableStatuses(
        appeal.value.status.id, appeal.value.modifyDate
    );
}

function showError(text) {
  alert.value.show(text, true);
}

const validThemes = computed(() => typeof appeal.value.themes !== 'undefined');

onMounted(async() => {
  let res = await getUser();
  if (res) {
    user.value = res;
    await _getAppeal();
    await _getMessages();
  } else {
    await router.push('/login');
  }
});
</script>

<template>
  <FileView ref="viewDialog"/>
  <BottomDialog ref="bottomDialog"/>
  <Alert ref="alert"/>
  <div v-if="flags.firstLoad" 
    class="d-flex justify-content-center align-items-center h-100">
    <div 
      class="loader spinner-border" 
      role="status"></div>
  </div>
  <div v-else 
    class="appeal container overflow-hidden content d-flex flex-row 
      flex-fill w-100 align-items-stretch">
    <appeal-chat 
      :appeal="appeal"
      :message-sending-callback="messageCallback"
      :error="showError">
    </appeal-chat>
    <!--Information panel-->
    <appeal-info-panel v-if="validThemes"
      ref="appealInfo"
      :appeal="appeal"
      :change-operator="_changeOperator"
      :change-status="_changeStatus"
      :alert="showAlert"
      :bottom-dialog="showBottomDialog"
      :operators="operators.items"
      :statuses="statuses.items">
    </appeal-info-panel>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/css/pages/appeal.scss';
</style>
