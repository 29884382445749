<script setup>
import { defineProps } from 'vue';


const props = defineProps({
  message: Object
});
</script>

<template>
  <div class="system-message d-flex flex-column justify-content-center 
      align-items-center padding-12 highlight-border-blue mt-3">
    <p class="system-message__text thin-font font-16 highlight-blue my-0">
      {{ props.message.comment }}
    </p>
  </div>
</template>

<style scoped>
.system-message {
  border: 1px solid;
  border-radius: 4px;
}
</style>