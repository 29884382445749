<script setup>
import { ref, defineExpose, nextTick } from 'vue'


const modal = ref(null);
const window = ref(null);
const isVisible = ref(false);

function show() {
  isVisible.value = true;
  
  nextTick(() => {
    modal.value.focus();
  });
}

function hide() {
  if (isVisible.value)
    isVisible.value = false
}

function close(event) {
  if (!window.value.contains(event.target))
    isVisible.value = false;
}

defineExpose({
  show,
  hide
});
</script>

<template>
  <transition 
    name="fade" 
    mode="out-in">
    <div v-if="isVisible" 
      class="dialog" 
      ref="modal" 
         @click="close" 
         tabindex="0" 
         @keydown.esc="hide()">
      <div 
        class="dialog__window" 
        id="window" 
        ref="window">
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-to, .fade-leave-from {
  opacity: 1;
}

.dialog {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  inset: 0;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  z-index: 2;
}

.dialog__window {
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
  background: #fff;
  border-radius: 5px;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
}
</style>