import httpClient from '@/api/httpService.js';
import { mapAppeal } from '@/utils/appeal-utils';

function getThemes(orgId) {
    return httpClient
        .get(`api/appeals/themes`, {
            params: {
                orgId: orgId
            }
        })
        .then((response) => {
            let result = {};
            result.themes = response.data;
            result.error = false;
            return result;
        })
        .catch(error => returnError(error));
}

function getAppeals(orgId, filter, search) {
    return httpClient
        .post('api/appeals/appeals', {
            search: search?.search ?? '',
            column: search.column,
            themes: filter.selectedThemes.map(f => f.id).join(','),
            statuses: filter.selectedStatuses.map(s => s.id).join(','),
            operators: filter.selectedOperators.map(op => op.userId).join(','),
            descending: search.descending,
            page: search.selectedPage,
            pageSize: search.pageSize,
            orgId: orgId,
            startDate: filter.selectedDateRange.startDate,
            endDate: filter.selectedDateRange.endDate
        })
        .then((response) => {
            let result = {};
            result.error = false;
            if (response.status === 200) {
                result = response.data;
                result.appeals = result.appeals.map(mapAppeal);
            } else {
                result.totalCount = 0;
            }
            return result;
        })
        .catch(error => returnError(error)); 
}

function getArchiveAppeals(orgId, filter, search) {
    return httpClient
        .post('api/appeals/archive-appeals', {
            search: search.search,
            column: search.column,
            themes: filter.selectedThemes.map(f => f.id).join(','),
            statuses: filter.selectedStatuses.map(s => s.id).join(','),
            operators: filter.selectedOperators.map(op => op.userId).join(','),
            descending: search.descending,
            page: search.selectedPage,
            pageSize: search.pageSize,
            orgId: orgId,
            startDate: filter.selectedDateRange.startDate,
            endDate: filter.selectedDateRange.endDate
        })
        .then((response) => {
            let result = {};
            result.error = false;
            if (response.status === 200) {
                result = response.data;
                result.appeals = result.appeals.map(mapAppeal);
            } else {
                result.totalCount = 0;
            }
            return result;
        })
        .catch(error => returnError(error));
}

function getOperators(orgId) {
    return httpClient
        .get('api/appeals/operators', {
            params: {
                orgId: orgId
            }
        })
        .then((response) => returnResponse(response))
        .catch(error => returnError(error));
}

function getMessages(appealId) {
    return httpClient
        .get(`api/appeals/messages`,  {
            params: {
                appealId: appealId
            }
        })
        .then((response) => returnResponse(response))
        .catch(error => returnError(error));
}

function setStatus(appealId, statusId) {
    return httpClient
        .post('api/appeals/update-status', { 
            appealId: appealId, 
            updateId: statusId.toString() 
        })
        .then((response) => {
            let result = response.data;
            result.error = result.status < 0;
            return result;
        })
        .catch(error => returnError(error));
}

function setOperator(appealId, operatorId) {
    return httpClient
        .post('api/appeals/set-operator', {
            appealId: appealId,
            updateId: operatorId.toString()
        })
        .then(response => returnResponse(response))
        .catch(error => returnError(error));
}

function sendMessage(message) {
    return httpClient
        .post(`api/appeals/create-message`, message)
        .then((response) => {
            let result = response.data;
            result.error = false;
            return mapAppeal(result);
        })
        .catch(error => returnError(error));
}

function getOrgAppeal(appealId, operatorId) {
    return httpClient
        .get('api/appeals/org-appeal', { 
            params: { 
                appealId: appealId,
                operatorId: operatorId
            } 
        })
        .then((response) => {
            let result = response.data;
            result.error = false;
            return mapAppeal(result);
        })
        .catch(error => returnError(error));
}

function uploadFile(file) {
    let formData = new FormData();
    formData.append('file', file);
    return httpClient
        .post(`api/appeals/file-upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
        })
        .then((response) => {
            let result = {};
            result.file = response.data;
            result.error = false;
            return result;
        })
        .catch(error => returnError(error));
}

function getAllThemes() {
    return httpClient
        .get('api/appeals/all-themes').then((response) => {
            let result = {};
            result.themes = response.data;
            result.error = false;
            return result;
        })
        .catch((e) => {
            let result = {};
            result.error = true;
            result.data = e;
            return result;
        });
}

function setThemes(orgId, categories) {
    let body = {
        orgId: orgId,
        categories: categories.map(th=>mapTheme(th))
    };
    return httpClient
        .post(`api/appeals/set-themes`, body
        )
        .then(response => returnResponse(response))
        .catch(error => returnError(error));
}

function loadFile(file) {
    let formData = new FormData();
    formData.append('file', file);
    return httpClient
        .post(`api/files/upload-file`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        )
        .then((response) => {
            let result = {};
            result.file = response.data;
            result.error = false;
            return result;
        })
        .catch(error => returnError(error));
}

function getFileUrl(filePath) {
    return httpClient
        .get('api/files/file', {
            params: {
                filePath: filePath
            }
        })
        .then(response => returnResponse(response))
        .catch(error => returnError(error));
}

function returnResponse(response) {
    let result = response;
    result.error = false;
    return result;
}

function returnError(e) {
    let result = {};
    result.error = true;
    if (e.response)
        result.status = e.response.status;
    result.data = e;
    return result;
}

function mapTheme(theme) {
    let category = {};
    category.id = theme.id;
    category.themeNames = [];
    for (let i = 0; i < theme.themes.length; i++) {
        category.themeNames.push(theme.themes[i].name);
    }
    return category;
}

export {
    getAppeals,
    getArchiveAppeals,
    getThemes,
    getOrgAppeal,
    getOperators,
    getMessages,
    setStatus,
    setOperator,
    sendMessage,
    uploadFile,
    getAllThemes,
    setThemes,
    loadFile,
    getFileUrl
}