<script setup>
import { defineProps } from 'vue';
import { formatNullableString } from '@/utils/text-utils';


const props = defineProps({
    appeal: Object
})
</script>

<template>
    <div class="property-info d-flex flex-column bg-white p-4 mt-3
        border-0 border-radius-4">
        <p class="property-info__title normal-font font-14 text-black no-margin-vertical">
            Информация об объекте недвижимости
        </p>
        <div class="property-info__account mt-3">
            <span class="property-info__account-title text-gray font-14 thin-font">
                Лицевой счёт пользователя: 
            </span>
            <span class="property-info__account-text text-black font-14 normal-font">
                {{ formatNullableString(props.appeal.account?.join(', ')) }}
            </span>
        </div>
        <div class="property-info__uid margin-top-14">
            <span class="property-info__uid-title text-gray font-14 thin-font">
                UID адреса пользователя:
            </span>
            <span class="property-info__uid-text text-black font-14 normal-font">
                {{ formatNullableString(props.appeal.unifiedAccountID) }}
            </span>
        </div>
        <p class="property-info__address-title text-gray font-14 thin-font margin-top-14">
            Фактический адрес пользователя: 
        </p>
        <p class="property-info__address text-black font-14 normal-font">
            {{ formatNullableString(props.appeal.address) }}
        </p>
    </div>
</template>