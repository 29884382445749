<script setup>
import AppealMessage from './AppealMessage.vue';
import MessageInput from './MessageInput.vue';
import { defineProps, ref, onMounted, reactive, computed } from 'vue';
import { getMessages } from '@/api/appeals';


const props = defineProps({
    messageSendingCallback: Function,
    appeal: Object,
    error: Function
});

const flags = reactive({
    messagesLoading: true,
    firstLoad: true,
    messageSending: false
});

const chat = ref(null);

const messages = reactive({
  items: []
});

async function _getMessages() {
  flags.messagesLoading = true;
  messages.items = props.appeal?.messages ?? [];
  
  let _messages = await getMessages(props.appeal?.appealId);
  if (!_messages.error) {
    if (_messages.data.length > 0)
      messages.items = _messages.data;
  }

  flags.messagesLoading = false;
}

const isAppealOpen = computed(() => !(props.appeal.status.id === 8 || props.appeal.status.id === 6));

const chatOverflown = computed(() => {
    let _chat = chat.value;
    if (_chat)
      return _chat.scrollHeight > _chat.clientHeight;

    return true;
  }
);

async function messageCallback(result) {
    props.messageSendingCallback(result);
    await _getMessages();
}

onMounted(async() => {
    await _getMessages();
    let chatDiv = document.getElementsByClassName('chat')[0];
    chatDiv.scrollTop = chatDiv.scrollHeight;
});
</script>

<template>
  <div class="appeal-chat no-left-padding d-flex flex-column
    white-background border-radius-4 h-100 w-75">
    <div ref="chat"
      class="appeal-chat__content chat
        overflow-scroll no-margin-vertical d-flex p-4 pb-0" 
        :class="{ 'flex-column-reverse': chatOverflown, 
        'flex-column': !chatOverflown,
        'flex-grow-1': flags.messagesLoading, 
        'open-appeal-chat': isAppealOpen,
        'closed-appeal-chat': !isAppealOpen }">
        <ul v-if="!flags.messagesLoading"
          class="appeal-chat__messages ps-0">
          <li v-for="message in messages.items" 
            class="appeal-chat__message list-without-style" 
            :key="message.messageId">
            <appeal-message 
              :message="message" />
          </li>
      </ul>
      <div v-if="flags.messagesLoading" 
        class="d-flex justify-content-center align-items-center h-100">
      <div class="loader spinner-border" 
        role="status"/>
      </div>
    </div>
    <message-input v-if="isAppealOpen"
      class="appeal-chat__reply-area"
      :appeal="props.appeal"
      :show-error="props.error"
      :callback="messageCallback"></message-input>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/css/pages/appeal.scss';
/* Hide scrollbar for Chrome, Safari and Opera */
.chat::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.chat {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.appeal-chat__reply-area {
  max-height: 50% !important;
  overflow-y: auto;
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 18px;
  padding: 4px;
}
</style>
