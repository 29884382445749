<script setup>
import Dialog from '@/components/popup/Dialog';
import { ref, defineExpose } from 'vue';


const file = ref(null);
const dialog = ref(null);

const show = (f) =>
{
  file.value = f;
  dialog.value.show();
}

const hide = () => {
  dialog.value.hide();
}

defineExpose({
  show,
  hide
});
</script>

<template>
  <Dialog ref="dialog">
    <img :src="file.src" alt="file-view">
  </Dialog>
</template>

<style lang="scss" scoped>
img {
  max-height: calc(100vh - 200px) !important;
  width: auto !important;
}
</style>