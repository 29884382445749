
function formatNullableString(str) {
    return (str && str.length > 0) ? str : '-';
}

function formatNullablePersonalString(str) {
    return (str && str.length > 0) ? str : 'не указано'
}

export {
    formatNullableString, 
    formatNullablePersonalString
}
