<script setup>
import AppealAttachment from './AppealAttachment';
import { download } from '@/utils/appeal-utils.js';
import { defineProps } from 'vue'; 


const props = defineProps({
  message: Object,
  onDownloadError: Function
});
</script>

<template>
  <div class="user-message d-flex flex-column px-0 mt-2">
    <p class="user-message__title text-gray font-14 thin-font my-0">
      От пользователя
    </p>
    <div class="user-message__info d-flex flex-column bg-gray 
      border-radius-4 padding-12 col-10 margin-top-14">
      <p class="user-message__date thin-font font-14 text-black mt-2 mb-0">
        {{ new Date(props.message.createDate).toLocaleString() }}
      </p>
      <div class="user-message__comment-title mt-2 mb-0">
        <span class="text-gray font-14 thin-font">
          Комментарий: 
        </span>
        <span class="user-message__comment thin-font text-black comment-text">
          {{ props.message.comment }}
        </span>
      </div>
      <!--Attachments-->
      <ul 
        id="attachments-list"
        class="user-message__attachments d-flex flex-row justify-content-between w-100" >
        <li 
          class="user-message__attachment list-without-style d-flex"
          v-for="attachment in props.message.files" 
          :key="attachment">
          <appeal-attachment 
                :attachment="attachment" 
                :is-local="false" 
                :on-click="download"
                :on-download-error="onDownloadError"/>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
.user-message__attachment {
  float: left;
  width: calc(33% - 12px);
}
</style>