<script setup>
import { ref, defineExpose, nextTick } from 'vue'


const window = ref(null);
const popup = ref(null);
const isVisible = ref(false);

function show() {
  isVisible.value = true;
  nextTick(() => {
    popup.value.focus();
  });
}

function hide() {
  if (isVisible.value)
    isVisible.value = false
}

function close(e) {
  if (!window.value?.contains(e.target)) {
    isVisible.value = false;
  }
}

defineExpose({
  show,
  hide,
  close
});
</script>

<template>
  <transition 
    name="slide" 
    mode="out-in">
    <div v-if="isVisible" 
      class="popup" 
      ref="popup" 
      tabindex="0"
      @click="close" 
      @keydown.esc="hide()">
        <div class="popup__content d-flex flex-row justify-content-between">
          <slot/>
          <div class="popup__close-content col-auto d-flex align-items-start 
            justify-content-center">
            <button 
              class="popup__close-button btn-close" 
              type="button" 
              @click="hide()"/>
          </div>
        </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
@import '@/assets/css/colors.scss';


.slide-enter-active, .slide-leave-active {
  transition: opacity .5s ease-in-out;
}

.popup {
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
  inset: 0;
  z-index: 2;
  background: #00000060;
}

.popup__content {
  background: $secondary-color;
  min-width: 25%;
  max-width: 33%;
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 7%;
  inset: 0;
  border-radius: 4px;
  padding: 24px;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
  bottom: 0;
}

.slide-enter-to, .slide-leave-from {
  opacity: 1;
}

.slide-enter-from, .slide-leave-to {
  opacity: 0;
}

.popup:focus {
  outline: none;
}

.btn-close {
  margin-left: 14px;
}
</style>