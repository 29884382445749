<script setup>
import OperatorMessage from './OperatorMessage';
import UserMessage from './UserMessage';
import SystemMessage from './SystemMessage';
import { defineProps } from 'vue';


const props = defineProps({
    message: Object
});
</script>

<template>
    <div class="message">
        <user-message 
            :message="props.message" 
            v-if="props.message.type === 0"/>
            <operator-message 
            :message="props.message" 
            v-if="props.message.type === 1"/>
            <system-message 
            :message="props.message" 
            v-if="props.message.type === 2"/>
    </div>
</template>