<script setup>
import { defineProps, ref, computed } from 'vue';


const props = defineProps({
    options: Array,
    chosenOption: Object,
    callback: Function,
    listName: String,
    optionNameField: String,
    optionKeyField: String,
    isAppealOpen: Boolean,
    text: String
});

const chosen = ref(props.chosenOption ?? null);

const buttonRole = computed(() => props.options.length != 0 && props.isAppealOpen ? 'button' : '');

function formatNullableString(str) {
  return (str) ? str : '-';
}

const layoutExpanded = ref(false);

function choseOption(option) {
    props.callback(option);
}
</script>

<template>
    <div 
        class="option-picker bg-white w-100 d-flex flex-column border-radius-4
        mt-3 px-4 py-3 user-select-none">
        <div 
            class="option-picker__preview-info d-flex flex-row"
            :role="buttonRole"
            data-bs-toggle="collapse"
            :data-bs-target="'#' + listName"
            :aria-controls="listName"
            @click="layoutExpanded = !layoutExpanded">
            <div class="option-picker__preview d-flex flex-column">
                <p class="option-picker__preview-text thin-font font-12 text-gray no-margin-vertical">
                    {{ text }}
                </p>
                <p 
                    class="option-picker__preview-option normal-font font-14 small-margin text-black"
                    :class="{
                        'highlight-blue':  chosen?.highlight === 'blue', 
                        'highlight-red': chosen?.highlight === 'red',
                        'highlight-gray': chosen?.highlight === 'gray'}">
                    {{ chosen ? chosen[optionNameField] : '-' }}
                </p>
            </div>    
            <div v-if="options.length > 0 && isAppealOpen"
                class="options-picker__expand ms-auto d-flex align-items-center">
                <img 
                    class="options-picker__expand-icon icon-24 user-select-none"
                    src="@/assets/img/icons/icon-arrow-down.svg"
                    :class="{'rotate-180': layoutExpanded}"
                    alt="arrow down"
                    draggable="false"/>
            </div>
        </div>
        <ul v-if="options.length > 0 && isAppealOpen"
            class="options-picker__dropdown white-background collapse mb-0 ps-0"
            :id="listName">
            <li
                class="options-picker__option list-without-style ps-2 pe-0
                left-border pt-2"
                v-for="option in options"
                :key="option ? option[optionKeyField] : null"
                @click="choseOption(option)">
                <p
                    class="options-picker__option-name mb-0 text-gray
                    normal-font font-14 px-0 card-list-item user-select-none"
                    role="button"
                    :class="{
                        'text-black': option?.[optionKeyField] == chosenOption?.[optionKeyField],
                        'highlight-blue':  option?.highlight === 'blue', 
                        'highlight-red': option?.highlight === 'red',
                        'highlight-gray': option?.highlight === 'gray'}">
                    {{ formatNullableString((option) ? option[optionNameField] : '-') }}
                </p>
            </li>
        </ul>
    </div>
</template>

<style lang="scss" scoped>
@import '@/assets/css/colors.scss';


.highlight-blue {
  color: $primary-color !important;
}

.highlight-red {
  color: $color-red !important;
}

.highlight-gray {
  color: $light-gray !important;
}
</style>