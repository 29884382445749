<script setup>
import OptionPicker from '@/components/layout/OptionPicker';
import MainInfo from './MainInfo.vue';
import PropertyInfo from './PropertyInfo.vue';
import UserInfo from './UserInfo.vue';
import DeviceInfo from './DeviceInfo.vue';
import { defineProps, defineExpose, reactive, ref, computed } from 'vue';


const props = defineProps({
    appeal: Object,
    alert: Function,
    bottomDialog: Function,
    changeOperator: Function,
    changeStatus: Function,
    operators: Array,
    statuses: Array
});

const renderKeys = reactive({
    optionPickers: 0
});

const flags = reactive({
    selectOperator: false,
    selectStatus: false
});

const selectedObj = ref(null);

function prepareSelectedOperator(item) {
    selectedObj.value = item;

    if (props.appeal.operator && item.userId == props.appeal.operator.userId) {
        props.alert('Необходимо выбрать другого оператора!');
        return;
    }
    
    props.bottomDialog(
        `Вы уверены что хотите изменить оператора обращения на ${item?.fio}?`,
        _changeOperator,
        item.userId
    );
}

function prepareSelectedStatus(item) {
    selectedObj.value = item;

    if (item.id === props.appeal.status) {
        props.alert('Необходимо выбрать другой статус!');
        return;
    }
    
    props.bottomDialog(
        `Вы уверены что хотите изменить статус обращения на '${item.text}'?`,
        _changeStatus,
        item.id
    );
}

async function _changeOperator(operatorId) {
    await props.changeOperator(operatorId);
    rerenderOptionPickers();
}

async function _changeStatus(statusId) {
    await props.changeStatus(statusId);
    rerenderOptionPickers();
}

function rerenderOptionPickers() {
    renderKeys.optionPickers++;
    flags.selectOperator = false;
    flags.selectStatus = false;
}

const isAppealOpen = computed(() => !(props.appeal.status.id === 8 || props.appeal.status.id === 6));

defineExpose({
    rerenderOptionPickers
});
</script>

<template>
    <div class="appeal-info d-flex flex-column h-100 ms-2 p-0 overflow-scroll">
        <main-info :appeal="appeal"></main-info>
        <div :key="renderKeys.optionPickers">
            <!--Operator change plate-->
            <option-picker v-if="operators.length > 0 || appeal.operator"
                    :options="operators"
                    list-name="operators-list"
                    option-name-field="fio"
                    option-key-field="userId"
                    :is-appeal-open="isAppealOpen"
                    :chosen-option="appeal.operator"
                    text="Сменить оператора, отвечающего за обращение"
                :callback="prepareSelectedOperator"></option-picker>
            <!--Status change plate-->
            <option-picker v-if="appeal.status"
                    :options="statuses"
                    list-name="status-list"
                    option-name-field="text"
                    option-key-field="id"
                    :is-appeal-open="isAppealOpen"
                    :chosen-option="appeal.status"
                    text="Текущий статус"
                :callback="prepareSelectedStatus"></option-picker>
        </div>
        <property-info :appeal="appeal"></property-info>
        <user-info v-if="appeal.user" 
            :user="appeal.user">
        </user-info>
        <device-info v-if="appeal.deviceInfo"
            :device-info="appeal.deviceInfo">
        </device-info>
    </div>
</template>