<script setup>
import BottomComponent from './BottomComponent';
import { ref, defineExpose } from 'vue'


const window = ref(null);
const text = ref('');
const confirm = ref(() => null);
const closeButtonVisibility = ref(true);

function show(txt, fun, isClose = true) {
  text.value = txt;
  confirm.value = fun;
  closeButtonVisibility.value = isClose;

  window.value.show();
}

function hide() {
  window.value.hide()
}

function confirmAction() {
  if (confirm.value)
    confirm.value()

  hide();
}

defineExpose({
  show
});
</script>

<template>
  <bottom-component ref="window">
    <div class="bottom-dialog d-flex flex-column">
      <div class="bottom-dialog d-flex flex-row">
        <p class="mid-font text-black font-16 no-margin-vertical">
          {{ text }}
        </p>
      </div>
      <div class="bottom-dialog__content d-flex flex-row justify-content-around">
        <div 
          class="bottom-dialog__image d-flex align-items-center justify-content-center margin-top-14"
          role="button" 
          @click="confirmAction">
          <img 
            src="../../assets/img/icons/icon-check-mark.svg" 
            alt="Подтверждение/отклонение">
        </div>
        <div v-if="closeButtonVisibility" 
             class="bottod-dialog__close d-flex align-items-center justify-content-center margin-top-14" 
             role="button" 
             @click="hide()">
          <img 
            src="../../assets/img/icons/icon-error.svg" 
            alt="Закрыть">
        </div>
      </div>
    </div>
  </bottom-component>
</template>

<style lang="scss" scoped>
img {
  width: 28px;
  height: 28px;
}
</style>